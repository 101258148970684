<script setup lang="ts">
  import PartialTeaserListTheme from '../../Partials/TeaserList/TeaserListTheme.vue'
  import PartialTeaserListOrdered from '../../Partials/TeaserList/TeaserListOrdered.vue'
  const { recommendationData } = defineProps<{
    recommendationData: object
  }>()

  const categoryId = recommendationData?.category.id
  const recomendedItems =
    recommendationData?.category?.['content-teasers']?.data || []

  const config = useRuntimeConfig()
  const url = basicUrl(config?.public?.API_URL)

  const { data: contentPopular } = await useFetch<any>(
    //`https://admin.woman.dk/wp-json/app/content/popular`
    `${url}/app/content/popular?categories=${categoryId}`
  )

  // Create a computed property to transform recomendedItems into TeaserListWrapper
  const contentTeaserWrapper = computed<TeaserListWrapper>(() => ({
    title: recommendationData?.name,
    label: null,
    link_label: null,
    category: {
      id: categoryId,
    },
    tag: null,
    display_hint: 'theme',
    theme: 'light',
    can_paginate: false,
    teasers: {
      data: recomendedItems,
    },
  }))

  const contentRecommendationWrapper = computed<TeaserListWrapper>(() => ({
    title: 'Mest Sete', // TODO replace with translation string
    label: null,
    link_label: null,
    category: {
      id: categoryId,
    },
    tag: null,
    display_hint: 'ordered_list',
    theme: 'light',
    can_paginate: false,
    teasers: {
      data: contentPopular.value.data.slice(0, 4),
    },
  }))
</script>

<template>
  <div class="gap-13 bp-main-grid">
    <section class="bp-main-grid full-content center-content">
      <PartialTeaserListTheme
        v-if="recomendedItems?.length > 0"
        data-component-type="Teaser List"
        data-component-name="theme"
        :teaserData="contentTeaserWrapper"
      />
    </section>
    <section class="bp-main-grid full-content center-content">
      <div id="mkt-placement-17" class="main-content"></div>
    </section>

    <section class="bp-main-grid full-content center-content">
      <PartialTeaserListOrdered
        data-component-type="Teaser List"
        data-component-name="ordered_list"
        v-if="contentPopular?.data?.length > 0"
        :teaserData="contentRecommendationWrapper"
      />
    </section>
  </div>
</template>

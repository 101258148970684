export function usePiano() {
  const contentStore = useContentStore()
  const userStore = useUserStore()
  const { pageType, getAllTags, content } = contentStore
  const { contentType } = useContentType()
  const { ParsedEvents } = useParsedEvents()

  const tpPush = (args: any[]) => {
    return new Promise<void>(resolve => {
      window.tp.push([...args, resolve])
    })
  }

  const PianoSetup = async () => {
    const userState = userStore?.userState
    tpPush(['setZone', pageType])
    tpPush(['setTags', getAllTags()])
    tpPush(['setCustomVariable', 'userState', userState])
    if (userState !== 'Annonymous') {
      const subActive =
        userStore?.user?.['custom:subscribed_brands'].split(',') || false

      tpPush(['setCustomVariable', 'user_name', userStore?.user?.email])
      tpPush([
        'setCustomVariable',
        'subs_expiration_time',
        userStore?.user?.['custom:subs_expiration_time'],
      ])
      tpPush(['setCustomVariable', 'accessToken', userStore?.user?.accessToken])
      tpPush([
        'setCustomVariable',
        'refresh_token',
        userStore?.user?.accessToken,
      ])
      if (subActive) {
        tpPush(['setCustomVariable', 'subsActive', subActive])
      }
    }
    if (pageType === 'content') {
      const customVariables = {
        compositeTitle: content?.title,
        compositeDescription: content?.description,
        userState: userState,
      }
      // Iterate over the customVariables object and set each variable individually
      for (const [key, value] of Object.entries(customVariables)) {
        tpPush(['setCustomVariable', key, value])
      }

      tpPush(['setContentSection', contentType.value.toLowerCase()])
    } else if (content?.is_front_page) {
      tpPush(['setContentSection', 'frontpage'])
    } else {
      tpPush(['setContentSection', ''])
    }
  }

  function handlerExists(handlerName: string) {
    return (
      window.tp &&
      window.tp.listOfAddedHandlers &&
      window.tp.listOfAddedHandlers.includes(handlerName)
    )
  }

  const PianoInit = async () => {
    await PianoSetup()

    if (!handlerExists('showTemplate')) {
      window.tp.push([
        'addHandler',
        'showTemplate',
        event => {
          window.dataLayer.push({
            event: 'piano impression',
            engagement: {
              type: 'impression',
              component: {
                name: 'MKT-Template-',
                id: `piano-${event.experienceId}-${event.templateId}-${event.templateVariantId}`,
              },
            },
            flow: {
              name: event.containerSelector
                .split(/ |\.|#|>|\+|~/gi)
                .reverse()[0],
            },
          })
        },
      ])
      window.tp.listOfAddedHandlers.push('showTemplate')
    }

    if (!handlerExists('checkoutCustomEvent')) {
      window.tp.push([
        'addHandler',
        'checkoutCustomEvent',
        event => {
          ParsedEvents(event)
        },
      ])
      window.tp.listOfAddedHandlers.push('checkoutCustomEvent')
    }
    window.tp.experience.execute()
  }

  const resetPiano = () => {
    const pianoStyles = document.querySelectorAll('.mkt-remove-on-exit')
    pianoStyles.forEach(element => {
      element.remove()
    })
    if (window.tp?.scrollDepth) {
      window.tp.scrollDepth.clearMaxScrolledPosition()
      window.tp.offer.closeInline('#piano-container')
      window.tp.offer.closeInline('.piano-container')
      window.tp.scrollDepth.initialize()
    }
  }

  return { PianoInit, PianoSetup, resetPiano }
}

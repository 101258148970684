import { localeWithCountryCode } from '../utils/localeUtils'
import { definePublisher } from './definePublisher'

export function defineCollectionPage(content: any) {
  const { $seo } = useNuxtApp()
  const fullUrl = `https://${useRuntimeConfig().public.SITE_URL + useRoute().path}`
  const translationStore = useTranslationStore()

  content = content?.data

  $seo.addSchema({
    '@type': 'CollectionPage',
    url: fullUrl,
    inLanguage:
      localeWithCountryCode(content?.locale || content?.language, true) || '',
    name: content.name,
    mainEntityOfPage: fullUrl + '#/webpage.collection',
    description:
      content?.details?.data?.description?.replace(/<[^>]*>?/gm, '') ||
      content?.teasers?.data[1]?.description?.replace(/<[^>]*>?/gm, '') ||
      content?.biography ||
      translationStore.getTranslation('meta.description'),
    publisher: definePublisher(),
  })
}

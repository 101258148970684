/**
 * @typedef {function(any): boolean} InlineRuleFunction - Type for the function that handles inline rules.
 * @typedef {function(any[], number): boolean} OverlayRuleFunction - Type for the function that handles overlay rules.
 */

/**
 * Function to apply inline rules.
 *
 * @param {any[]} items - The array of items to process.
 * @param {string} ruleName - The name of the inline rule to apply.
 * @param {Object} rules - The object containing inline rules.
 * @returns {any[]} - The array of items with the inline rules applied.
 */
function applyInlineRules(
  items: any[],
  ruleName: string,
  rules: { [key: string]: InlineRuleFunction }
): any[] {
  const rule = rules[ruleName]
  if (!rule) {
    throw new Error(`Rule ${ruleName} not found`)
  }

  return items.map(item => {
    const shouldLock = rule(item)
    const processedItem = shouldLock
      ? { ...item, PaywallProtected: true }
      : { ...item, PaywallProtected: false }
    return processedItem
  })
}

/**
 * Function to apply overlay rules.
 *
 * @param {any[]} items - The array of items to process.
 * @param {string} ruleName - The name of the overlay rule to apply.
 * @param {Object} rules - The object containing overlay rules.
 * @returns {Object} - An object containing two arrays: locked and unlocked items.
 */
function applyOverlayRules(
  items: any[],
  ruleName: string,
  rules: { [key: string]: OverlayRuleFunction }
): { unlocked: any[]; locked: any[] } {
  const rule = rules[ruleName]
  if (!rule) {
    throw new Error(`Rule ${ruleName} not found`)
  }

  const result = { unlocked: [], locked: [] }
  let isUnLocked = false

  items.reduce((acc, item, index) => {
    if (!isUnLocked && rule(acc, index)) {
      isUnLocked = true
      result.unlocked = [...acc]
      result.locked = []
    }

    if (isUnLocked) {
      result.locked.push(item)
    } else {
      acc.push(item)
    }

    return acc
  }, [])

  return result
}

/**
 * Function to create and return inline rules.
 *
 * @returns {Object} - An object containing multiple inline rules.
 */
function createInlineRules() {
  return {
    locked1: (item: any) => {
      return item.type === 'gallery'
    },
    locked2: (item: any) => {
      return (
        item.type === 'text_item' ||
        item.display_hint === 'associated_composites' ||
        item.type === 'gallery'
      )
    },
    // Add more rules here as needed
  }
}

/**
 * Function to create and return overlay rules.
 *
 * @returns {Object} - An object containing multiple overlay rules.
 */
function createOverlayRules() {
  return {
    overlay1: (items: any[], index: number) => index === 1,
    overlay2: (items: any[], index: number) => index === 3,
    // Add more rules here as needed
  }
}

/**
 * Function to create and return paywall placement functions.
 *
 * @param {string} type - The type of rules to apply ("inline" or "overlay").
 * @param {string} ruleName - The name of the rule to apply.
 * @param {any[]} items - The array of items to process.
 * @returns {any[] | Object} - The processed items.
 */
function createPaywallPlacement(type: string, ruleName: string, items: any[]) {
  const inlineRules = createInlineRules()
  const overlayRules = createOverlayRules()

  if (type === 'inline') {
    return applyInlineRules(items, ruleName, inlineRules)
  } else if (type === 'overlay') {
    return applyOverlayRules(items, ruleName, overlayRules)
  } else {
    throw new Error(`Unknown type: ${type}`)
  }
}

/**
 * Factory function to create and return paywall template functions.
 *
 * @returns {Object} - An object containing functions to create and apply inline and overlay rules.
 */
export function usePianoTemplates() {
  return {
    createInlineRules,
    applyInlineRules,
    createOverlayRules,
    applyOverlayRules,
    createPaywallPlacement,
  }
}

async function handleNewsletterSignup(event: any) {
  const { params } = event
  const { customBrandCode, customSourceCode, email, name, qualifiox3 } = params

  // Prepare the data to be sent
  const data = {
    name,
    email,
    customBrandCode,
    customSourceCode,
    qualifiox3,
  }

  try {
    // Perform the $fetch POST request
    await $fetch('/api/newsletter', {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    console.error('Error:', error)
  }
}

export function useParsedEvents() {
  const eventNamesHandlers: { [key: string]: (event: any) => void } = {
    'newsletter-signup': handleNewsletterSignup,
    // Add more event handlers to the mapping as needed
  }

  function ParsedEvents(event: any) {
    const customEventName =
      event.customEventName || event.params.customEventName

    const EventNamehandler = eventNamesHandlers[customEventName]

    if (EventNamehandler) {
      EventNamehandler(event)
    }
    const firstNonEmptyValue = arr => {
      for (const i in arr) {
        if (arr[i] in event.params && event.params[arr[i]] !== '')
          return event.params[arr[i]]
      }
      return ''
    }
    const getDestinationType = datapoint => {
      const hostname = window.location.hostname
      if (datapoint && datapoint.includes(hostname)) {
        return 'internal'
      }
      return 'external'
    }
    const isDestinationUrlEmpty = url => {
      return url === null || url === ''
    }
    const isDestiantionUrlSelf = url => {
      return url === window.location.href
    }
    const addProtocolToUrl = url => {
      if (!url.startsWith('http://') || !url.startsWith('https://')) {
        return 'https://' + url
      }
      return url
    }
    const dataPoint3 = firstNonEmptyValue([
      'dataLayerWidgetDestinationUrl',
      'dataLayerEngagementDestinationUrl',
      'dataLayerUrl',
      'dataLayerAdDomain',
    ])
    const dataPoint4 = firstNonEmptyValue([
      'dataLayerWidgetDestinationTitle',
      'dataLayerAdType',
      'dataLayerEngagementComponentType',
      'dataLayerType',
    ])
    const dataPoint5 = firstNonEmptyValue([
      'dataLayerEngagementEventParamsClickedText',
      'dataLayerClickedText',
    ])
    const dataPoint6 = firstNonEmptyValue([
      'dataLayerWidgetPlacement',
      'dataLayerWidgetDestinationPlacement',
      'dataLayerAdOrderId',
      'dataLayerEngagementComponentId',
      'dataLayerId',
    ])
    const getEngagementType = (typeOnly = false) => {
      const getClickType = datapoint4 => {
        const clickTypes = [
          'click',
          'swipe',
          'toggle',
          'close',
          'open',
          'collapse',
          'expand',
        ]
        const delimiters = /[\s\-.,#>+~]+/
        const newDataPoint = datapoint4
          .replace('expand-close', 'collapse')
          .replace('expand-open', 'expand')
          .replace('exit-click', 'close')
        const words = newDataPoint.split(delimiters)
        const finalWord = words[words.length - 1]
        if (clickTypes.includes(finalWord)) {
          return finalWord
        }
        return 'click'
      }
      return !typeOnly
        ? (isDestinationUrlEmpty(dataPoint3) || isDestiantionUrlSelf(dataPoint3)
            ? 'interaction '
            : 'link ') + getClickType(dataPoint4)
        : getClickType(dataPoint4)
    }
    const getComponentName = datapoint4 => {
      const delimiters = /[\s\-.,#>+~]+/
      const parts = datapoint4.split(delimiters)
      return parts.slice(0, 3).join('-')
    }
    const engagement = {
      type: getEngagementType(true),
      component: {
        name: getComponentName(dataPoint4),
        type: dataPoint4,
        id: dataPoint6,
        position: event.containerSelector.split(/\.|#|>|\+|~/gi).reverse()[0],
      },
      eventParams: {
        clickedText: dataPoint5 !== '' ? dataPoint5 : 'undefined',
      },
    }
    if (dataPoint3 !== '') {
      engagement.destination = {
        type: getDestinationType(dataPoint3),
        url: addProtocolToUrl(dataPoint3),
      }
    }
    if (event.params?.dataLayerDataTrigger) {
      window.dataLayer.push({
        event: getEngagementType(),
        // ...normalizedData,
        engagement,
      })
      // New Piano dataLayer want this second dataLayer entry with undefined!!!
      window.dataLayer.push({
        engagement: undefined,
        eventParams: undefined,
      })
    }

    if (!EventNamehandler) {
      console.warn(`No handler found for event: ${customEventName}`)
    }
  }

  return { ParsedEvents }
}

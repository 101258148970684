<script setup lang="ts">
  import RecommendationDefault from './Partials/Recommendation/RecommendationDefault.vue'
  import RecommendationVideo from './Partials/Recommendation/RecommendationVideo.vue'

  const { contentTypeConfig } = useContentType()

  const componentMap: ComponentMap = {
    default: RecommendationDefault,
    video: RecommendationVideo,
  }

  const recommendationData = defineProps<{
    data: RecommendationData
    type?: string
  }>()

  const selectedComponent = computed(
    () => componentMap[contentTypeConfig.value?.contentRecommendation] || null
  )
</script>

<template>
  <section
    v-if="selectedComponent"
    class="wide-content my-15 bp-main-grid"
    data-position="Below content"
  >
    <div class="full-content">
      <component
        :is="selectedComponent"
        :recommendationData="recommendationData.data"
      />
    </div>
  </section>
</template>
